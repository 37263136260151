.button {
  position: absolute;
  font-size: 0.5em;
  top: 1em;
  right: 1em;
  width: 25em;
  background-color: white;
  border-color: #aaaaaa;
  border-style: solid;
  border-radius: 0.3em;
  border-width: 0.1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.1em;
  padding-bottom: 0.2em;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}
.button:hover {
  background-color: #eeeeee;
}
.button:active {
  background-color: #aaaaaa;
}
.button:focus {
  background-color: #aaaaaa;
}
